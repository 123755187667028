<template>
  <div class="form-group">
    <label>
      {{$t('trade.order.address')}}
      (
      <router-link :to="{name: 'address', params: $route.params, query: $route.query}">
        <b class="text-link">{{$t('common.button.modify')}}</b>
      </router-link>
      )
    </label>
    <p class="text-ellipsis text-small">{{address.firstName}} {{address.lastName}}</p>
    <p class="text-ellipsis text-small">{{address.line2}} {{address.line1}}</p>
    <p class="text-ellipsis text-small">{{address.city}}, {{address.state}}</p>
    <p class="text-ellipsis text-small">{{country}}</p>
    <p class="text-ellipsis text-small">{{address.postalCode}}</p>
  </div>
</template>

<script>
export default {
  name: 'address-info',
  props: {
    address: {
      type: Object,
      default: function () {
        return {
          firstName: '曉琪',
          lastName: '鄭',
          line1: '台中市南屯區春社里建功路240巷1弄7號 台中市南屯區春社里同安南巷25-56號',
          line2: '',
          city: '南屯區',
          state: '台中',
          countryCode: 'TW',
          country: '台灣',
          postalCode: '408',
          phone: ''
        }
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    country () {
      return this.address.country ? this.address.country : this.address.countryCode
    }
  },
  created () {}
}
</script>

<style lang="scss">

</style>
