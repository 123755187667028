import { isEmpty, find } from 'lodash'
export default {
  methods: {
    handleBackEndPrice (prices) {
      const bookPrice = find(prices, { priceType: 'book' })
      const deliveryPrice = find(prices, { priceType: 'delivery' })
      if (isEmpty(bookPrice.value) || isEmpty(deliveryPrice.value)) {
        return this.$toast({
          message: '价格列表为空，请联系客服'
        })
      }
      // 获取区间价格
      const a5Price = bookPrice.value.a5
      try {
        Object.keys(a5Price).map((item) => {
          a5Price[item].inner = a5Price[item].inner[200] // TODO：暂时取200的价格
        })
      } catch (e) {
        console.error(e)
      }
      return Promise.resolve({ book: a5Price, delivery: deliveryPrice.value })
    }
  }
}
